export default {
  footerBottomText:
    "Verivest is not a law firm or a registered investment advisor, and it does not provide legal or investment advice. While Verivest can help you structure your deal and create the documents you need to raise capital, Verivest strongly recommends that you review those documents with securities counsel before raising capital from investors. Verivest does not recommend any of the sponsors or investment opportunities referenced on this site, including any opportunities offered through documents Verivest helped to create. All private investments involve risk and uncertainty, including lower than projected returns and a complete risk of loss of invested capital.",
  columns: [
    {
      title: "Product",
      links: [
        {
          url: "/get-started",
          linkName: "Fund Builder",
          linkLabel: "Fund Builder",
          linkDark: false,
          isMarketing: false,
          directMainLink: false,
          isNew: true,
        },
        {
          url: "/pricing",
          linkName: "Pricing",
          linkLabel: "Pricing",
          linkDark: false,
          isMarketing: true,
          directMainLink: false,
        },
      ],
    },

    {
      title: "Solutions",
      links: [
        {
          url: "/for-managers",
          linkName: "For Managers",
          linkLabel: "For Managers",
          linkDark: false,
          isMarketing: true,
          directMainLink: false,
        },
        {
          url: "/for-investors",
          linkName: "Investors",
          linkLabel: "Investors",
          linkDark: false,
          isMarketing: true,
          directMainLink: false,
        },
      ],
    },
    {
      title: "Company",
      links: [
        {
          url: "https://verivest.breezy.hr/",
          linkName: "Careers",
          linkLabel: "Careers",
          linkDark: false,
          isMarketing: false,
          directMainLink: false,
        },
      ],
    },
    {
      title: "Resources",
      links: [
        {
          url: "https://support.verivest.com",
          linkName: "Help Center",
          linkLabel: "Help Center",
          linkDark: true,
          isMarketing: false,
          directMainLink: false,
        },
        {
          url: "mailto:support@verivest.com",
          linkName: "Support",
          linkLabel: "Support",
          linkDark: false,
          isMarketing: false,
          directMainLink: false,
        },
        {
          url: "mailto:sales@verivest.com",
          linkName: "Sales",
          linkLabel: "Sales",
          linkDark: false,
          isMarketing: false,
          directMainLink: false,
        },
      ],
    },
  ],
  socials: {
    facebookLink: {
      url: "https://www.facebook.com/verivestverified/",
      linkName: "Facebook Link",
      linkLabel: "Facebook",
      linkDark: false,
      isMarketing: false,
      directMainLink: false,
    },
    instagramLink: {
      url: "https://www.instagram.com/verivestverified/?hl=it",
      linkName: "Instagram Link",
      linkLabel: "Instagram",
      linkDark: false,
      isMarketing: false,
      directMainLink: false,
    },
    twitterLink: {
      url: "https://twitter.com/verivest",
      linkName: "Twitter Link",
      linkLabel: "https://twitter.com/verivest",
      linkDark: false,
      isMarketing: false,
      directMainLink: false,
    },
    linkedinLink: {
      url: "https://www.linkedin.com/company/verivest",
      linkName: "Linkedin Link",
      linkLabel: "Linkedin",
      linkDark: false,
      isMarketing: false,
      directMainLink: false,
    },
  },
};
