import { Box, Flex, keyframes, useTheme } from "@chakra-ui/react";
import {
  Button,
  SwimRingIcon,
  openUrlInNewWindow,
  useAuth,
} from "@verivest/shared-components";
import { AnimatedMobileMenuButton } from "@verivest/shared-components/src/components/common/AnimatedMobileMenuButton";
import { ProfileButton } from "@verivest/shared-components/src/components/common/ProfileButton";
import React from "react";
import { useIsMobile } from "../../../../hooks/useIsMobile";
import { NotificationBell } from "../../../general";
import { ImpersonatingHeader } from "../ImpersonatingHeader";
import { ManagerIcon } from "../ManagerIcon/ManagerIcon";
import { InvestorPortalHeaderBaseProps } from "./InvestorPortalHeaderBase.types";
import { useGetHeaderHeight } from "./useGetHeaderHeight";
import { useInvestorPortal } from "../../InvestorPortalContext";

export const DEFAULT_HEADER_HEIGHT = 79 as const;

const shadowInAnimationKeyFrames = keyframes`
  from {box-shadow: none;}
  30% { box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16) }
  100% {box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16) }
`;

const shadowOutAnimationKeyFrames = keyframes`
  from {box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16)}
  30% { box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16) }
  100% { box-shadow: none; }
`;

const headerShadowInAnimation = `${shadowInAnimationKeyFrames} .6s ease-in-out`;
const headerShadowOutAnimation = `${shadowOutAnimationKeyFrames} .6s ease-in-out`;

export const InvestorPortalHeaderBase: React.FC<
  InvestorPortalHeaderBaseProps
> = ({
  children,
  hasLogo,
  hasMobileDrawer,
  hasShadow,
  isMarketing,
  ...rest
}) => {
  const { auth } = useAuth();
  const isImpersonating = auth.isManagerImpersonating();
  const isMobile = useIsMobile();
  const theme = useTheme();
  const { isMobileMenuOpen, setIsMobileMenuOpen } = useInvestorPortal();
  const headerHeight = useGetHeaderHeight();
  const headerHeightPx = `${headerHeight}px`;

  const goToHelpAndSupport = () => {
    openUrlInNewWindow("https://support.verivest.com");
  };

  return (
    <>
      <Box h={headerHeightPx} />
      <Flex
        id={"ip3-header-base"}
        pos={"fixed"}
        flexDirection={"column"}
        top={0}
        left={0}
        right={0}
        borderBottom={"1px solid #D7D9E0"}
        alignItems={"center"}
        boxShadow={hasShadow && !isMobileMenuOpen ? 2 : undefined}
        h={{ md: headerHeightPx }}
        py={{ base: 3, md: 0 }}
        px={3}
        bg={"white"}
        zIndex={isMobileMenuOpen ? { base: "popover", md: "inherit" } : 11}
        animation={
          !isMarketing
            ? hasShadow
              ? headerShadowInAnimation
              : headerShadowOutAnimation
            : undefined
        }
        css={{
          "&.sub-menu-fixed": { borderBottom: "1px solid transparent" },
        }}
        {...rest}
      >
        {isImpersonating && <ImpersonatingHeader />}
        <Flex
          w={"100%"}
          alignItems={"center"}
          flexDirection={{ base: "column-reverse", md: "row" }}
          {...theme?.investorPortal?.fixed}
          pos={{ base: "relative", md: "static" }}
          zIndex={{ base: "popover", md: "inherit" }}
        >
          {(!isMobile || !hasMobileDrawer) && (
            <Flex alignItems={"center"} flexGrow={1} mt={{ base: 3, md: 0 }}>
              <Box
                transition={"width 0.2s"}
                overflow={"hidden"}
                h={hasLogo ? "100%" : 0}
              >
                <ManagerIcon />
              </Box>
              {children}
            </Flex>
          )}
          <Flex
            className={"w-full items-center gap-4 justify-between md:w-auto"}
          >
            {hasMobileDrawer && (
              <AnimatedMobileMenuButton
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                isActive={isMobileMenuOpen}
                display={{ md: "none" }}
                width={28}
              />
            )}
            {isMobile && hasLogo && <ManagerIcon />}
            <Box className={"hidden sm:block"} flexGrow={1}>
              <Button
                onClick={goToHelpAndSupport}
                px={3}
                py={2}
                borderRadius={"27px"}
                border={"1px solid #e3e3e3"}
                alignItems={"center"}
                leftIcon={SwimRingIcon}
                leftIconProps={{ boxSize: 6, color: "ink" }}
                bg={"white"}
                textProps={{
                  color: "ink",
                  textStyle: "bodyRegular",
                  fontWeight: "normal",
                  alignItems: "center",
                }}
                empty
              >
                Help & Support
              </Button>
            </Box>
            <NotificationBell />
            <ProfileButton />
          </Flex>
        </Flex>
      </Flex>
      {/*{hasMobileDrawer && isMobile && (
        <VeriDrawer
          size={"full"}
          onClose={isMobileDrawerOpen.false}
          isOpen={isMobileDrawerOpen.state}
          noHeaderShadow
          hideCloseButton
        >
          <Box mt={headerHeightPx}>{children}</Box>
        </VeriDrawer>
      )}*/}
    </>
  );
};
